import React from "react";

const AboutPage = () => {
    return (
        <div className="p-8 text-white">
            <h1 className="text-4xl font-bold mb-4">紹介</h1>
            <p>
                ここ数カ月、お米の供給不足により、日々近所のスーパーマーケットにはお米が手配できないか、
                高い値段で購入しなければならない状況が続いている。日本政府ではお米の平均価格を1年間隔で発表しているため、リアルタイム性がない。
                そのため、1日単位でお米の値段が分かるようにした。
            </p>
        </div>
    );
};

export default AboutPage;
