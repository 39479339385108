import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import FrontPage from "./pages/FrontPage";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import RiPage from "./pages/RiPage";
import ReleaseNotePage from "./pages/ReleaseNotePage";
import Footer from "./pages/Footer";

const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<FrontPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/service" element={<ServicePage />} />
                <Route path="/release-notes" element={<ReleaseNotePage />} />
                <Route path="/ri" element={<RiPage />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
