import React, { useEffect, useRef, useState } from "react";
import { createChart, LineStyle, LineSeries } from "lightweight-charts";

const RiceChart = () => {
    const chartContainerRef = useRef();
    const [priceData, setPriceData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://oj1brz5qr4.execute-api.ap-northeast-1.amazonaws.com/dev/rice-data"
                );
                const result = await response.json();
                const parsedData = JSON.parse(result.body);

                const groupedData = parsedData.reduce((acc, item) => {
                    const { price, date, "site_platform#product_id": productId } = item;
                    if (!acc[productId]) {
                        acc[productId] = [];
                    }
                    acc[productId].push({ time: date, value: price });
                    return acc;
                }, {});

                setPriceData(groupedData);
            } catch (error) {
                console.error("데이터 가져오기 실패:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (Object.keys(priceData).length === 0) return;

        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 500,
            layout: {
                background: {
                    color: "#121212"
                },
                textColor: "#FFFFFF", // 글자 흰색
            },
            grid: {
                vertLines: {
                    color: "#333",
                },
                horzLines: {
                    color: "#transparent",
                },
            },
            crosshair: {
                mode: 0,
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                borderColor: "#555",
            },
            priceScale: {
                borderColor: "#555",
            },
        });

        // 일반 상품 색상 (고정된 색상 사용)
        const productColor = "#00BFFF"; // 밝은 파란색

        let totalPricesByDate = {};
        let countByDate = {};

        // 각 상품별 LineSeries 추가
        Object.keys(priceData).forEach((productId) => {
            const lineSeries = chart.addSeries(LineSeries, {
                color: productColor, // 모든 상품은 같은 색
                lineWidth: 2,
            });

            lineSeries.setData(priceData[productId]);

            // 평균 가격 계산을 위해 데이터 누적
            priceData[productId].forEach(({ time, value }) => {
                if (!totalPricesByDate[time]) {
                    totalPricesByDate[time] = 0;
                    countByDate[time] = 0;
                }
                totalPricesByDate[time] += value;
                countByDate[time] += 1;
            });
        });

        // 평균 가격 데이터 생성
        const averageData = Object.keys(totalPricesByDate).map((date) => ({
            time: date,
            value: totalPricesByDate[date] / countByDate[date],
        }));

        // 평균가 라인 추가 (흰색 + 점선)
        const averageSeries = chart.addSeries(LineSeries, {
            color: "#FFFFFF", // 흰색
            lineWidth: 2,
            lineStyle: LineStyle.Dotted, // 점선 스타일
        });

        averageSeries.setData(averageData);

        // // "평균가" 라벨 추가
        // averageSeries.applyOptions({
        //     lastValueVisible: true, // 마지막 값 라벨 보이기
        //     priceLineVisible: true, // 가격 라인 보이기
        //     priceLineColor: "#FFFFFF", // 흰색으로 강조
        //     priceLineWidth: 2,
        //     priceLineStyle: LineStyle.Dotted, // 점선 스타일
        // });

        chart.timeScale().fitContent();

        return () => chart.remove();
    }, [priceData]);

    return <div ref={chartContainerRef} style={{ width: "100%", height: "500px" }} />;
};

export default RiceChart;
