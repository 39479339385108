import React from "react";

const ServicePage = () => {
    return (
        <div className="p-8 text-white">
            <h1 className="text-4xl font-bold mb-4">サービス説明</h1>
            <p>
                令和のお米騒動の影響でお米の価格が急騰している現状を受け、日々のお米価格の平均値を一目で確認できる「RI（Rice Index）」を提唱し、
                日別・週別・月別単位でお米の価格推移をチャートで提供しています。
            </p>
        </div>
    );
};

export default ServicePage;
