import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="flex items-center justify-between p-4 bg-gray-900 text-white">
            {/* 로고 */}
            <Link to="/" className="text-2xl font-bold">
                OKOMETAKA
            </Link>

            {/* 네비게이션 링크 */}
            <div className="space-x-8">
                <Link to="/" className="hover:underline">ホーム</Link>
                <Link to="/about" className="hover:underline">紹介</Link>
                <Link to="/service" className="hover:underline">サービス説明</Link>
                <Link to="/ri" className="hover:underline">RIとは？</Link>
                <Link to="/release-notes" className="hover:underline">リリースノート</Link>
            </div>
        </nav>
    );
};

export default Navbar;
