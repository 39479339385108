import React from "react";

const ProductCard = ({ product, rank }) => {
    return (
        <div className="flex items-center justify-between p-4 bg-gray-900 rounded-lg shadow mb-4">
            {/* 순위 */}
            <div className="text-lg font-semibold w-8 text-center text-white">{rank}</div>

            {/* 이미지 */}
            <img
                src={product.image_url || "https://via.placeholder.com/100"}
                alt={product.name}
                className="w-16 h-16 object-cover rounded border"
            />

            {/* 제품 정보 */}
            <div className="flex-1 ml-4 text-white">
                <a href={product.product_url} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                    {product.name}
                </a>
            </div>

            {/* 가격 및 리뷰 수 */}
            <div className="text-right text-white min-w-[100px]">
                <div className="font-bold text-lg">¥{product.price.toLocaleString()}</div>
                <div className="text-sm text-gray-400">({product.review_count.toLocaleString()})</div>
            </div>
        </div>
    );
};

export default ProductCard;
