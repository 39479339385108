import React, { useEffect, useState } from "react";
import ProductCard from "../components/ui/ProductCard";
import RiceChart from "../components/RiceChart";

const FrontPage = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://oj1brz5qr4.execute-api.ap-northeast-1.amazonaws.com/dev/top-reviewed");
        const data = await response.json();
        const parsedData = JSON.parse(data.body);
        setProducts(parsedData);
      } catch (error) {
        console.error("상품 데이터를 불러오는 데 실패했습니다:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="p-4">
      {/* 차트 섹션 */}
      <div className="mb-8">
        <div className="text-center text-lg text-gray-300 mb-4">
          今のお米の値段って、どのくらい高くなってるんだろう…？
        </div>
        <RiceChart />
      </div>

      {/* 인기 제품 섹션 */}
      <section>
        <h2 className="text-2xl font-bold text-white mb-4">人気のお米製品</h2>
        {products.map((product, index) => (
          <ProductCard key={product["site_platform#product_id"]} product={product} rank={index + 1} />
        ))}
      </section>
    </div>
  );
};

export default FrontPage;
