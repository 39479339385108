import React from "react";

const RiPage = () => {
    return (
        <div className="p-8 text-white">
            <h1 className="text-4xl font-bold mb-4">RIとは？</h1>
            <p>
                RI（Rice Index）
                日本国内のお米の平均値格を表す指数であり、以下の選定基準に基づいて算出しています。
            </p>
        </div>
    );
};

export default RiPage;
