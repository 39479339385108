// components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <div style={infoStyle}>
                <span style={itemStyle}>SEONGJAE KANG</span>
                <span style={separatorStyle}>|</span>
                <span style={itemStyle}>
                    <a href="mailto:flame3104100@gmail.com" style={linkStyle}>
                        flame3104100@gmail.com
                    </a>
                </span>
                <span style={separatorStyle}>|</span>
                <span style={itemStyle}>
                    <a
                        href="https://www.linkedin.com/in/flame91/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={linkStyle}
                    >
                        LinkedIn
                    </a>
                </span>
            </div>
            <div style={messageStyle}>
                このページは
                <a href="http://okome.xyz" target="_blank" rel="noopener noreferrer" style={linkStyle}>
                    okome.xyz
                </a>
                からでもアクセスできます
            </div>
        </footer>
    );
};

const footerStyle = {
    backgroundColor: '#333', // ダークグレーの背景色
    color: '#fff', // 白色のテキスト
    textAlign: 'center',
    padding: '1em 0',
    width: '100%',
};

const infoStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap', // 狭い画面で折り返しを許可
    gap: '0.5em', // 要素間の間隔
    marginBottom: '0.5em', // 下部の余白
};

const itemStyle = {
    margin: '0',
};

const separatorStyle = {
    margin: '0 0.5em',
};

const messageStyle = {
    marginTop: '0.5em',
};

const linkStyle = {
    color: '#ccc', // ライトグレーのリンク
    textDecoration: 'none',
};

export default Footer;
