import React from "react";

const ReleaseNotePage = () => {
    const releaseNotes = [
        {
            date: "3/2",
            content: "セキュリティ強化のため、S3の前にCloudFrontを設定\n"
                + "- Block public access を有効化\n"
                + "- Bucket policy の変更 (CloudFront からのみアクセス可能に設定)\n"
                + "- ホスティングタイプの変更 (静的ウェブサイト → オブジェクトリクエストのリダイレクト)\n"
                + "- プロトコルの変更: HTTP → HTTPS (ACM で CNAME 証明書を発行)"
        },
        { date: "2/27", content: "Release note ページ追加" },
        { date: "2/26", content: "Footer 追加" },
        { date: "2/25", content: "商品リスト追加" },
        { date: "2/24", content: "ベター版リリース" },
        {
            date: "予定",
            content: "多国語対応\nチャートUI改善\nスクレイピングバッチ処理(今は手動)\nRIの多角化(品種別、容量別など)"
        }
    ];

    return (
        <div className="max-w-2xl mx-auto p-6 text-white">
            <h1 className="text-3xl font-bold mb-6">リリースノート</h1>
            <ul className="space-y-4">
                {releaseNotes.map((note, index) => (
                    <li key={index} className="border-l-4 border-gray-500 pl-4">
                        <h2 className="text-xl font-semibold">{note.date}</h2>
                        <p className="whitespace-pre-line">{note.content}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ReleaseNotePage;
